<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="activity_create_back">
      <el-button type="text" @click="handleBack">
        <i class="el-icon-arrow-left"></i>返回活动列表</el-button
      >
      <el-button
        type="primary"
        v-if="detailRow.isVisible"
        @click="handleClick('down')"
        >下 架</el-button
      >
      <el-button
        type="primary"
        v-else-if="!detailRow.isVisible"
        @click="handleClick('up')"
        >上 架</el-button
      >
    </div>

    <div class="activity_create_bg" style="display: flex">
      <div style="margin-right: 200px">
        <h4>活动信息</h4>
        <p>
          {{ detailRow.activityTitle
          }}<span v-if="detailRow.activityTitleUs"
            >（{{ detailRow.activityTitleUs }}）</span
          >
        </p>
        <p>活动时间：{{ detailRow.startTime }}——{{ detailRow.endTime }}</p>
        <p>
          活动位置：{{ detailRow.activityLocation
          }}<span v-if="detailRow.activityLocationUs">
            （{{ detailRow.activityLocationUs }}）
          </span>
        </p>
        <p>
          活动简介：{{ detailRow.activitySubtitle
          }}<span v-if="detailRow.activitySubtitleUs">
            （{{ detailRow.activitySubtitleUs }}）
          </span>
        </p>
        <p>
          退订规则：{{
            detailRow.cancelRule == 0
              ? "不可退订"
              : detailRow.cancelRule == 1
              ? "提前1天退订"
              : detailRow.cancelRule == 2
              ? "提前3天退订"
              : detailRow.cancelRule == 3
              ? "提前7天退订"
              : detailRow.cancelRule == 4
              ? "提前14天退订"
              : ""
          }}
        </p>
      </div>
      <div>
        <h4>活动报名信息</h4>
        <p>报名价格：{{ detailRow.activityPrice }}</p>
        <p>报名数量：{{ detailRow.activityStock }}</p>
        <p>报名时间：{{ detailRow.startEnlist }}——{{ detailRow.endEnlist }}</p>
        <p>
          报名级别：{{
            detailRow.activityGrade == 0
              ? "无限制"
              : detailRow.activityGrade == 1
              ? "行世界等级A"
              : detailRow.activityGrade == 2
              ? "行世界等级B"
              : detailRow.activityGrade == 3
              ? "行世界等级C"
              : detailRow.activityGrade == 4
              ? "行世界等级D"
              : ""
          }}
        </p>
      </div>
    </div>

    <div class="activity_create_bg">
      <h4>订单列表</h4>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.primaryImage"
          label="活动主图"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="
                scope.row.worldActivityOrderResponse.activityDTO.primaryImage
              "
              :preview-src-list="[scope.row.primaryImage]"
              v-if="
                scope.row.worldActivityOrderResponse.activityDTO.primaryImage
              "
            >
            </el-image>
            <div
              v-if="
                !scope.row.worldActivityOrderResponse.activityDTO.primaryImage
              "
            >
              暂无图片
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.activityTitle"
          label="活动名称"
        >
          <template slot-scope="scope">
            <div>
              中文：{{
                scope.row.worldActivityOrderResponse.activityDTO.activityTitle
              }}
            </div>
            <div
              v-if="
                scope.row.worldActivityOrderResponse.activityDTO.activityTitleUs
              "
            >
              英文：{{
                scope.row.worldActivityOrderResponse.activityDTO.activityTitleUs
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.activityPrice"
          label="单价"
        >
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.activityStock"
          label="数量"
        >
        </el-table-column>
        <el-table-column label="实收款">
          <template slot-scope="scope">
            {{
              scope.row.worldActivityOrderResponse.payOrder.payType
                ? scope.row.worldActivityOrderResponse.payOrder.payType == 1
                  ? `$ ${scope.row.worldActivityOrderResponse.payOrder.amountTotal}`
                  : `$ ${scope.row.worldActivityOrderResponse.payOrder.p95Amount}`
                : "$ 0"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.payOrder.orderId"
          label="订单号"
        >
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.payOrder.createTime"
          label="下单时间"
        >
        </el-table-column>

        <el-table-column prop="" label="订单状态" width="180" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.worldActivityOrderResponse.bizOrder.orderStatus
              }}<br />
              <el-button type="text" @click="handleOrderDetail(scope.row)"
                >详情</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { format_time_date, format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "活动发布管理",
        },
        {
          name: "活动详情",
        },
      ], // 面包屑数据

      tableData: [],
      detailRow: {},

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.detailRow = JSON.parse(localStorage.getItem("worldActivityDetailRow"));
    console.log(this.detailRow);
    this.detailRow.startEnlist = format_time_date_noTime(
      this.detailRow.startEnlist
    );
    this.detailRow.endEnlist = format_time_date_noTime(
      this.detailRow.endEnlist
    );

    showLoading();
    const opt = {
      url: reqUrl.worldActivityOrderListOrder,
      method: "POST",
      params: JSON.stringify({
        activityId: this.detailRow.id,
        orderId: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          this.tableData.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    handleBack() {
      this.$router.push("/worldActivityList");
    },

    handleOrderDetail(row) {
      if (row.payExamineVO != null) {
        this.$router.push({
          path: "/worldActivityOrderDetail",
          query: {
            orderId: row.payExamineVO.orderId,
          },
        });
      } else {
        this.$message.error("无法查看详情");
      }
    },

    handleClick(type) {
      this.$confirm("您确定要进入此操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.worldActivityInfoEdit,
            method: "POST",
            params: JSON.stringify({
              id: this.detailRow.id,
              isVisible: type == "down" ? false : true,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                if (type == "down") {
                  this.$message({
                    message: "下架成功",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: "上架成功",
                    type: "success",
                  });
                }
                this.handleBack();
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldActivityOrderListOrder,
        method: "POST",
        params: JSON.stringify({
          activityId: this.detailRow.id,
          orderId: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    changeItem(item) {
      let worldResOrder = item.worldActivityOrderResponse.bizOrder;

      item.worldActivityOrderResponse.payOrder.createTime = format_time_date(
        item.worldActivityOrderResponse.payOrder.createTime
      );

      if (worldResOrder.orderStatus == 1) {
        worldResOrder.orderStatus = "未支付";
      } else if (worldResOrder.orderStatus == 2) {
        worldResOrder.orderStatus = "支付审核中";
      } else if (worldResOrder.orderStatus == 3) {
        worldResOrder.orderStatus = "支付审核通过，已支付";
      } else if (worldResOrder.orderStatus == 4) {
        worldResOrder.orderStatus = "支付审核拒绝";
      } else if (worldResOrder.orderStatus == 5) {
        worldResOrder.orderStatus = "用户取消";
      } else if (worldResOrder.orderStatus == 6) {
        worldResOrder.orderStatus = "平台取消";
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
</style>
